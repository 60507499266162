import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

import { Layout } from "../components"

import styles from "./faq.module.css"

export default ({ data }) => (
  <Layout>
    <div className={styles.content}>
      <ReactMarkdown
        allowDangerousHtml={true}
        children={data.allContentfulPage.edges[0].node.text.text}
      />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allContentfulPage(
      filter: { contentful_id: { eq: "5sJ9yeDyoNSuxzPP6Ymw8k" } }
    ) {
      edges {
        node {
          title
          text {
            text
          }
        }
      }
    }
  }
`
